<template>
  <v-container fluid pa-0 class="fill-height">
    <v-row no-gutters class="fill-height">
      <v-col cols="12" md="12" lg="12" sm="12" class="pa-2">
        <keep-alive>
        <router-view />
      </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

///SET A ROUTER VIEW for zero loc entity view
export default {
  components: {
  },
  mounted() {
  },
  computed: {
  },
  mounted() {},
  created() {}
};
</script>
